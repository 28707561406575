// extracted by mini-css-extract-plugin
export var fadeInBottom = "page-hero-module--fade-in-bottom--Tpviq";
export var fadeOutTop = "page-hero-module--fade-out-top--lkLKs";
export var headline = "page-hero-module--headline--6ni0l";
export var headline_red = "page-hero-module--headline_red---G4QC";
export var hero = "page-hero-module--hero--4IY5X";
export var hero_bannerText = "page-hero-module--hero_bannerText--If6F8";
export var hero_image = "page-hero-module--hero_image--qE2JX";
export var hero_imageAccent = "page-hero-module--hero_imageAccent--Sr11b";
export var hero_imageMain = "page-hero-module--hero_imageMain--ODABb";
export var hero_title = "page-hero-module--hero_title--RT-4m";
export var hero_titleWrapper = "page-hero-module--hero_titleWrapper--hFq1P";
export var hero_wrapper = "page-hero-module--hero_wrapper--SBeW7";
export var imageOverlay = "page-hero-module--imageOverlay--UJ-F9";
export var imageOverlay_lg = "page-hero-module--imageOverlay_lg--CiVEF";
export var imageOverlay_sm = "page-hero-module--imageOverlay_sm--VjXmw";