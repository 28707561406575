import * as React from "react";

import { GatsbyImage, getImage, StaticImage, withArtDirection } from "gatsby-plugin-image";
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import { hero, hero_wrapper, hero_title, hero_titleWrapper, 
    hero_bannerText, hero_image, hero_imageAccent,
    imageOverlay, imageOverlay_sm, imageOverlay_lg, hero_imageMain,
    } from './page-hero.module.scss';

const PageHero = ({data}) => {

    const images = withArtDirection(
        getImage(data.bannerImage), 
        [
            {
                media: "(max-width: 750px)",
                image: getImage(data.smallBannerImage),
            },
        ] 
                    
    )
    
    return (
        <section className={hero}>
            <div className={hero_wrapper}>
                <div className={hero_title}>
                    <div className={hero_titleWrapper}>
                        <div className={hero_bannerText}>
                            {renderRichText(data.bannerText)}
                        </div>
                    </div>
                </div>
                <div className={hero_image}>
                    <div className={hero_imageAccent}>
                        <StaticImage src="../../assets/images/hero-heart-sm.png" alt="" 
                            className={`${imageOverlay} ${imageOverlay_sm}`}  loading="eager"/>
                        <StaticImage src="../../assets/images/hero-heart.png" alt="" 
                            className={`${imageOverlay} ${imageOverlay_lg}`}  loading="eager"/>
                    </div>
                    <GatsbyImage image={images} alt="" className={hero_imageMain} loading="eager" />
                </div>
            </div>
        </section>

    )

}

export default PageHero