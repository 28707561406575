import * as React from 'react';

import { graphql, useStaticQuery } from "gatsby";

import Layout from '../components/Layout/layout';
import PageHero from '../components/PageHero';
import ReferAFriendForm from '../components/ReferAFriendForm';
import { Seo } from '../components/Seo/seo';
import TextBlockSection from '../components/TextBlockSection';

const ReferAFriendPage = () => {
    const data = useStaticQuery(graphql`
    {
      allContentfulPageLayout(filter: {id: {eq: "4abd1a4e-adb3-5fcb-8e70-f56958ca15ef"}} ) {
        edges {
          node {
            name
            title
            content {
              ... on ContentfulPageHero {
                id
                name
                bannerImage {
                  gatsbyImageData(
                    width: 1440
                    height: 345
                    quality: 100
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                  )
                }
                bannerText {
                  raw
                }
                smallBannerImage {
                  gatsbyImageData(
                    width: 950
                    height: 345
                    quality: 100
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                  )
                }
              }
              ... on ContentfulTextBlock {
                id
                name
                title {
                  raw
                }
                body {
                  body
                }
                colorScheme
              }
            }
            contactForm
          }
        }
      }
      allContentfulLocation {
        edges {
          node {
            phoneNumber
          }
        }
      }
    }
  `)

  return (
    <Layout>
            <PageHero data={data.allContentfulPageLayout.edges[0].node.content[0]} />
            <TextBlockSection data={data.allContentfulPageLayout.edges[0].node.content[1]}/>
            <ReferAFriendForm data={data.allContentfulLocation.edges[0].node.phoneNumber} />
    </Layout>
  )
}

export const Head = () => (
  <Seo 
    title="Refer a friend and get paid!" 
    description="The TGL team believes in personal connections and rewarding for quality.  Refer a friend to travel with TGL and recieve $1,000."
    pathname="/refer-a-friend"
  />
)

export default ReferAFriendPage