import * as React from "react";

import { renderRichText } from 'gatsby-source-contentful/rich-text'

import { textBlock, textBlock_wrapper, textBlock_titleWrapper, textBlock_bodyWrapper } from './text-block-section.module.scss';

const TextBlockSection = ({data}) => {

    return (
        <section className={`${textBlock} ${data.colorScheme}`}>
            <div className={textBlock_wrapper}>
                {data.title &&
                    <div className={textBlock_titleWrapper}>
                        {renderRichText(data.title)}
                    </div>
                }
                <div className={textBlock_bodyWrapper}>
                    <p>
                        {data.body.body}
                    </p>
                </div>
            </div>
            
        </section>
    )
}

export default TextBlockSection